
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pending-offers {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  .content {
    margin: 0 auto;
    &.container {
      padding: 40px 15px 15px;
    }
  }
}
.person-icon {
  font-size: 40px;
  padding: 5px 0 0;
}
.white-card.ooa-pending-offers {
  padding: 30px 0 0;
  margin-bottom: 40px;
}
.inProgressApplication {
  margin-bottom: 40px;
  padding: 30px 0;
}
.application-details .item {
  display: inline-block;
  margin-right: 15px;
}
.application-details .item.spacer {
  min-width: 40.1%;
}
.notification-alert {
  max-width: 100%;
  margin-bottom: 40px;
}
::v-deep .underline-text {
  text-decoration: underline;
}
@include desktop {
  .PendingOffers {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet-wide {
  .PendingOffers {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet {
  .PendingOffers {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
  }
}
@include mobile {
  .application-details .item {
    display: block;
    width: 100%;
  }
  .application-details .item.proceedbtn {
    text-align: left;
  }
  .white-card,
  .white-card.ooa-pending-offers {
    padding: 30px 10px;
  }
}
