
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.addressHeader {
  padding: 8px;
  min-height: 72px;
  height: auto;
  background: $color-third;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12); //vuetify copy
}

.v-card {
  border-top-left-radius: 0 !important; //!important to override vuetify styles
  border-top-right-radius: 0 !important;
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
