
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationBanner {
  position: relative;
  padding: 30px;
  h1 {
    display: inline;
    a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: $color-focus;
      }
      &:focus {
        color: white;
      }
    }
  }
}

@include mobile {
  #app .ApplicationBanner.ApplicationBanner--training {
    top: $header-height-training-small;
    &.ApplicationBanner--welcome {
      top: $header-height-training-large;
    }
  }
}

.logo-card {
  border-radius: 0 !important;
}
