
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSaveCancel {
  width: 100%;
  text-align: right;
  padding: 0 $content-padding;
  font-size: 1.6rem;

  .bar {
    border-top: 2px solid $color-primary;
    padding: 0.75rem 0;
  }

  button {
    margin: 0 0 0 0.5rem;
    padding: 1rem 2rem;
    border: 0;
    min-width: 7rem;
    background-color: $color-primary !important;

    &:focus {
      outline: 3px solid $ads-blue-2 !important;
    }
  }
}
