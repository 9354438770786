
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.BrowserSupportNotification {
  &.v-snack {
    z-index: z('snackbar');
  }

  ::v-deep .v-snack__content .notification__header .notification__close-btn {
    margin-left: auto;
  }
  ::v-deep .v-snack__content .notification__actions button {
    margin-left: 0;
  }
}

.notification {
  &__content {
    color: $color-primary;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__header {
    align-items: center;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__actions {
    font-size: 1.6rem;
    display: flex;

    a {
      text-decoration: none;
    }

    > :last-child {
      margin-left: 3rem;
    }
  }
}

.warning-icon {
  margin-top: 0.7rem;
  margin-right: 1.6rem;
}

::v-deep .v-snack__content {
  display: flex;
  align-items: flex-start;
  padding-bottom: 1rem;
}

h1 {
  font-size: 1.6rem;
  margin-bottom: 0;
}

@media (max-width: 580px) {
  .notification__actions {
    flex-direction: column;
    > :last-child {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}
