
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppMessage {
  display: flex;
  align-items: center;
  margin: $content-padding;
  padding: $content-padding;
  border-radius: $field-border-radius;
  font-size: 1.6em;

  &.warning {
    border: 1px solid $color-warning-border;
    background-color: $color-warning;
  }

  &.error {
    border: 1px solid $color-red;
    background-color: $color-red-lighter;
  }

  .icon {
    margin-right: 0.6em;
  }
}
