
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pending-application {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  button {
    font-size: 1.6rem;
    width: 20rem;
  }
  .content {
    margin: 0 auto;
    &.container {
      padding: 40px 15px 15px;
    }
  }
  &__card-separator {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.2;
    margin: 3rem 0;
    text-align: center;
    font-size: 2.2rem;
  }
}

.person-icon {
  font-size: 4rem;
  padding: 5px 0 0;
}

.in-progress-application {
  margin-bottom: 40px;
}

@include desktop {
  .new-application {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet-wide {
  .new-application {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}
@include tablet {
  .new-application {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
  }
}
