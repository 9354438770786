
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFieldCollection {
  h2 {
    padding: 0 $content-padding;
    margin: 0 0 3.5rem;
    font-weight: $text-medium;
  }
  &.no-footer {
    margin-bottom: 4rem;
  }
  .collectionItemFields {
    background: $color-grey-2;
    padding: $content-padding;
    margin: $content-padding;
    flex-shrink: 1;
    .collectionItemFields {
      padding: 0;
      margin: 0;
    }
    .collectionFooter {
      button.primary {
        padding: 0;
        color: $color-primary;
        background: transparent !important;
      }
    }
  }
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .columnLayout {
    display: inline-block;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    & > * {
      width: 100%;
    }
  }
  .collectionFooter {
    .ApplicationFieldAlert {
      padding: 0;
      margin: 0.5rem 0;
    }
    margin: 0.5rem 0 4rem 0;
    &.full {
      padding: 0 1rem;
      .add {
        margin: 0;
        display: block;
        text-align: center;
        width: 100%;
      }
    }
  }
  .add {
    &.bold {
      font-weight: bold;
    }
    margin: 0 0 0 $content-padding;
    padding: 1rem 2rem;
    border: 0;
    min-width: 7rem;
    font-size: 1.6rem;
  }
  .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;
    padding-top: 0.5rem;
  }
}

.collapse-all-button {
  @include button-reset;
  display: block;
  margin-left: auto;
  margin-right: 1rem;
  font-size: 1.6rem;
  border: none;

  &--active {
    .v-icon {
      transform: rotate(-180deg);
    }
  }
}
