// SCREEN SIZE CONDITIONALS:

// NOTE: There is no "mobile" conditional as this app
// is "mobile first", so mobile styling is the default.

// This is the point at which we swap to a sidebar layout

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin tablet-wide {
  @media (min-width: 769px) and (max-width: 991px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

// iPhone 5,6 exceptions - portrait orientation

@mixin old-iphone {
  @media screen and (max-width: #{$breakpoint-iphone}) and (-webkit-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin iphone-5 {
  @media screen and (max-width: #{$breakpoint-iphone-5}) {
    @content;
  }
}

// GLOBAL PLACEHOLDERS

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@media print {
  html {
    width: 210mm;
    height: 297mm;
    padding: 0;
    margin: 0;
    display: block;
    overflow: auto;
    body {
      padding: 0;
      margin: 0;
      display: block;
      overflow: visible;
      .AppHeader {
        display: none;
      }
      .container {
        overflow: visible;
        display: block;
        max-width: 100%;
        width: 100%;
        .col-lg-4 {
          display: none;
        }
        .mainContent {
          overflow: visible;
          display: block;
          min-width: 200mm;
          max-width: 210mm;
          width: 200mm;
          font-size: 11pt;
        }
      }
    }
  }
}
@page {
  overflow: visible;
  size: A4;
  margin: 10mm 10mm 10mm 5mm;
}

@mixin button-reset {
  margin: 0;
  border: none;
  padding: 0;
  font-weight: inherit;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

@mixin vuetify-label {
  margin-bottom: -1.2rem;
  margin-top: -1.2rem;
  z-index: 2;
  margin-left: 1rem;
  padding: 0 0.3rem;
  font-size: $text;
  border-radius: 0;
  font-weight: bold;
  color: $color-primary;
  background-color: $color-white;
  position: absolute;
}

@mixin vuetify-input {
  background-color: $color-white;
  padding: 0 1rem;
  height: 5.6rem;
  border-color: $color-grey-60;
  border-radius: 4px;
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}