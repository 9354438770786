
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppMessageBox {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z('modal');
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);

  .box {
    margin: 1rem;
    padding: $content-padding;
    max-width: 600px;
    min-width: 350px;
    border-radius: 0.5rem;
    background-color: $color-white;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  }

  .body {
    display: flex;
    margin: 0.5em 0 1.75rem;
    align-items: center;
    color: black;

    .icon {
      margin-right: 0.25rem;
      color: $color-primary;
      font-size: 2.5rem;
    }

    p {
      margin: 0;
    }
  }

  .footer {
    direction: rtl;
    text-align: right;
  }

  button {
    min-width: 6rem;
    border: none;
    font-size: $text;
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
