
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppFooter {
  background-color: $color-primary;
  height: $footer-height;
  display: flex;
  align-items: center;

  .footer-logo {
    flex-grow: 0;
    padding: 0 0 0 15px;
    margin: 0;
    min-width: 67px;

    .logo {
      height: 8.4rem;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    color: $color-white;
    font-size: $text;
    text-decoration: none;

    > :not(:last-child) {
      margin-right: 8rem;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: $text;
      line-height: $text-h4;
      padding-bottom: 1.2rem;
    }

    &__list {
      margin-bottom: 0;

      > :not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .list-link {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 1rem;
    }

    &__additional-info {
      padding-left: 3.4rem;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: $color-white;
    font-size: $text;
    font-weight: 500;
    display: inline-block;
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px white;
    }
  }

  @include tablet {
    & {
      height: auto;
    }
    .container {
      padding: 2rem;
    }
    .footer-logo {
      margin-right: 2rem;
    }
  }

  @include mobile {
    & {
      .footer-logo {
        margin-top: 2rem;
      }

      .row {
        display: block;
      }
      .links {
        display: block;
        &__privacy,
        &__support {
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
