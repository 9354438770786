// -----------------------------------------------------------------------------------
// Defines base styles for our web app. Component styles live within their components.
// -----------------------------------------------------------------------------------

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: $color-text-body;
  height: 100%;
  h1,
  h2 {
    font-weight: $text-bold;
  }
  h3 {
    font-weight: $text-medium;
  }

  &#body {
    font-family: $font-family;
    background-color: $color-bg-app;
  }

  input::-ms-clear {
    // Hide IE "cross" icon in input fields to make the
    // experience consistent across all browsers.
    display: none;
  }

  .nowrap {
    white-space: nowrap;
  }

  .breakWord {
    hyphens: auto;
  }

  // Global input/textarea placeholders color - Chrome/Safari/Mozilla/Opera/IE
  // INPUT 'line-height' was inherit from body settings - (line-height: normal) keep proper Chr/Saf/Moz/Ope and fixes IE view.
  #app {
    height: 100%;
    input {
      line-height: normal;

      @include placeholder {
        color: $color-placeholders;
      }
    }
    .main {
      min-height: calc(100vh - #{$footer-height});
      padding: 0 0 3rem;
    }
    select {
      max-width: 100%;
    }
    .ApplicationBanner {
      // banner floationg background images
      top: $header-height;
      overflow: hidden;
      background-color: $color-primary;
      .container {
        position: relative;
        height: 100%;
        .bgLeftElement {
          position: absolute;
          height: 360px;
          width: 360px;
          background-size: 360px 100px;
          background: url(./assets/brand-left-circle.svg) 0 0 no-repeat;
        }
        .bgRightElement {
          position: absolute;
          height: 196px;
          width: 523px;
          background-size: 196px 523px;
          background: url(./assets/brand-right-shapes.png) 0 0 no-repeat;
        }
        .headline {
          position: absolute;
          height: 100%;
          padding-left: 0;
        }
        .bannerTitle {
          position: relative;
          padding: 0 15px;
          left: 0px;
          top: 50%;
          transform: translate(0, -50%);
          text-align: left;
          z-index: z("base") + 1;
        }
        h1 {
          color: $color-white;
          line-height: 1.2;
          margin: 0;
        }
        h3 {
          color: $color-white;
        }
      }
    }
    h2 {
      color: $color-primary;
    }
    .iNote {
      font-style: italic;
    }
    .horizontal-list {

      li {
        &.prefill {
          &:before {
            background: url(./assets/icon-pencil.svg) 0 0 no-repeat;
          }
        }
        &.come-back {
          &:before {
            background: url(./assets/icon-come-back.svg) 0 0 no-repeat;
          }
        }
        &.what-happens {
          &:before {
            background: url(./assets/icon-what-happens.svg) 0 0 no-repeat;
          }
        }
      }
    }
    .ErrorView {
      .ApplicationBanner {
        margin-bottom: $header-height;
      }
      .bannerTitle {
        max-width: 600px;
        h1 {
          font-size: 48px;
        }
      }
    }
  }

  input,
  select,
  textarea,
  button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
    -webkit-appearance: none; // Prevents iOS adding unwanted field border styles
  }

  input:not([type="button"]),
  select,
  textarea {
    padding: 0.7rem 1rem;
    border: $field-border;
    border-radius: $field-border-radius;
    background-color: $color-field-background;
    color: black;
    font-size: 1.6rem;
    &:focus {
      transition: $basic-transition;
      outline: none;
      border: solid 1px $color-primary;
      box-shadow: 0 0 0 2px $color-primary;
    }
  }

  input[type='date'] {
    // Date inputs can gain some additional height depending on
    // browser. The only way I can make them consistent with other
    // inputs is with this magic number  - roughly:
    //    line-height: 1.2 + 1em of padding + 2px of borders
    height: 2.313rem;
  }

  input,
  select,
  textarea,
  button,
  a {
    &:focus {
      position: relative; // Ensures focus outline is not covered by adjacent elements
    }
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  legend {
    padding: 0;
    margin: 0.5em 0;
    display: inline-block; // IE needs this to keep legend within parent
    width: 100%; // IE needs this to keep legend within parent
  }

  h1 {
    font-size: 1.75rem;
  }

  p,
  li {
    font-size: 1.6rem;
    font-weight: $text-medium;
  }

  a,
  .btn-link {
    transition: $basic-transition;
    color: $color-primary;
    text-decoration: underline;
    font-weight: $text-bold;
    outline: none;
    &:hover {
      color: $color-red;
    }
  }

  a {
    &.btn-outline-primary {
      color: $color-primary !important;
      background-color: $color-white !important;
      border: 2px solid $color-primary !important;
      text-decoration: none !important;
      &:hover {
        background-color: $color-grey-hover !important;
      }
    }
  }

  button,
  input[type='button'] {
    border: 2px solid $color-primary;
    background-color: transparent;
    color: $color-primary;
    border-radius: 0.25rem;
    font-weight: $text-medium;
    padding: 0.5rem;
    margin: 0.25rem;
    cursor: pointer;

    &.primary {
      background-color: $color-primary;
      color: $color-white;
    }

    &.btn-outline-primary {
      color: $color-primary !important;
      background-color: $color-white !important;
      border: 2px solid $color-primary !important;
      &:hover {
        text-decoration: none !important;
        background-color: $color-grey-hover !important;
      }
    }
  }

  button[disabled],
  input[type='button'][disabled] {
    cursor: default;
    opacity: 0.25;
  }

  button:not([disabled]),
  input[type='button']:not([disabled]) {
    &:hover {
      text-decoration: underline;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; // Hides default drop button
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' width='1.5em' viewBox='0 0 28 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.9 1.1L8 7.2l6.1-6.1L16 2.9l-8 8-8-8 1.9-1.8z'/%3E%3Cpath fill='none' d='M2-5.8h24v24H2v-24z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 2rem;
    background-size: 1.5rem;
  }

  select::-ms-expand {
    display: none; // Hides default drop button for IE
  }

  select[disabled] {
    cursor: default;
    background-image: none;
  }

  .AppTypeAhead_input {
    // overwrite default vue-bootstrap-typeahead scoped style
    padding: 0 2rem;
    line-height: 3.5rem;
    height: 5rem;
    border-radius: 0.4rem;
    border: 1px solid $color-grey-30;
    margin: 0 1rem 0 0;
    font-size: $text;
    background: transparent;
    transition: $basic-transition;

    &:focus,
    &:active {
      outline: none;
      border-color: $color-primary;
    }

    @include placeholder {
      color: $color-placeholders;
    }
  }

  .AppTypeAhead_options {
    font-size: $text;
    max-height: 260px;
    .selected {
      // keydown (arrows keys action) style
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-white;
    }
  }

  .AppTypeAhead_option {
    &:hover {
      display: flex !important; // that allow to make dropdown clickable on IE10=>UP
      background-color: $color-primary;
      border-color: $color-primary;
      outline: none;
      color: $color-white;
    }
  }
  .white-card {
    background-color: $color-white;
    margin: 0;
    padding: 29px 20px 30px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
  }
}

// Icons
.icon {
  &.icon--danger {
    color: $color-red;
  }
}

//Override vuetify's padding
.v-application {
  .container,
  .col {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#app.v-application {
  //Override some vuetify stylings
  p {
    margin-bottom: 1rem;
  }

  .v-list-item__title {
    font-size: 1.6rem;
  }

  &.theme--light {
    background-color: $color-bg-app;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;

    .headline {
      font-family: $font-family !important;
    }

    .v-icon {
      color: $color-text-body;
    }
  }

  .v-expansion-panel-header {
    border: none;
    font-weight: bold;
    color: inherit;
    font-size: 1.6rem;
    margin: 0;

    &:hover {
      text-decoration: none;
    }
  }

  .v-select .v-input__control input[readonly] {
    //Overriding css set up for input:not([type="button"]) and updating general style
    border: none;
    background: none;
    box-shadow: none;
    padding: inherit;
  }

  // Sets drop-list row highlight colours
  .theme--light.v-list-item.v-list-item--highlighted:before {
    // Sets light grey row highlight when using up/down keys
    opacity: 0.07;
    background-color: black;
  }
  .theme--light.v-list-item.v-list-item--active:before {
    // Sets light blue row highlight for currently selected item
    opacity: 0.3;
    background-color: #407ec9;
  }
  .theme--light.v-list-item.v-list-item--active.v-list-item--highlighted:before {
    // Sets slightly darker blue row highlight for currently selected item that
    // is ALSO the item navigated to using up/down keys
    opacity: 0.4;
    background-color: #407ec9;
  }

  button.v-btn:not(.v-btn--round):not(.v-btn--outlined) {
    // Sets Vuetify button defaults for standard navy button.
    // "#app.v-application" prefix necessary to ensure precedence over Vuetify styles
    // without having to resort to "!important"
    border: none;
    background-color: $color-primary;
    color: white;
    font-weight: bold;
    padding: 0.5em 1em;
    letter-spacing: inherit;
    text-transform: none;

    // Allows buttons to expand with their content without being truncated by Vuetify default height
    min-height: 36px;
    height: auto;

    &.v-btn--text {
      background: none;
    }

    &.v-size--default {
      font-size: inherit;
    }
    &:hover {
      text-decoration: none;
    }
    &:disabled {
      opacity: 1;
    }
  }

  button.v-btn--round {
    //remove the underline on hovering vuetify round button. TO DO: remove this once vuetify fully implemented
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }

  button.v-btn--outlined {
    text-transform: none;
  }

  .v-card__title {
    font-weight: bold;
    padding: 1rem;
    font-size: 1.25rem;
  }

  .v-card__text {
    padding: 1rem;
  }

  .v-alert,
  .v-card__text {
    font-size: inherit;
  }

  //Slightly lift label text (normal is -24px)
  .v-text-field--outlined .v-label--active {
    transform: translateY(-26px) scale(0.75);
  }

  .theme--light.v-text-field .v-label,
  .theme--light.v-input--radio-group legend {
    color: $color-primary;
    font-weight: bold;
    font-size: 2.13rem; //vuetify scales the label by 0.75 so we need to set as 21.3px to get 16px actually displayed
    height: 22px // Also needed due to scale causing letters to cutoff
  }
}


// Banner elements scaling on mobile devices
@include desktop {
  #app {
    .ApplicationBanner {
      h1 {
        font-size: 50px;
      }
      h3 {
        font-size: 26px;
        padding-top: 8px;
      }
      .bgLeftElement {
        left: -350px;
        bottom: -280px;
      }
      .bgRightElement {
        right: -220px;
        bottom: -108px;
      }
    }
  }
}
@include tablet-wide {
  #app {
    .ApplicationBanner {
      .container {
        .headline {
          h1 {
            font-size: 38px;
          }
          h3 {
            font-size: 20px;
          }
          .bgLeftElement {
            height: 250px;
            left: -240px;
            bottom: -190px;
          }
          .bgRightElement {
            height: 130px;
            right: -330px;
            bottom: -50px;
          }
        }
      }
    }
  }
}
@include tablet {
  #app {
    &.view-Welcome, &.view-SchoolReminder {
      .site-header__title {
        display: none;
      }
    }
    .ApplicationBanner {
      .container {
        .headline {
          padding-left: 10px;
          h1 {
            font-size: 30px;
          }
          h3 {
            font-size: 18px;
          }
          .bgLeftElement {
            height: 200px;
            left: -190px;
            bottom: -150px;
          }
          .bgRightElement {
            height: 120px;
            right: -360px;
            bottom: -70px;
            background-size: 320px 120px;
          }
        }
      }
    }
  }
}
@include mobile {
  #app {
    .ApplicationBanner {
      .container {
        padding: 0 15px;
        .headline {
          padding: 0;
          .bannerTitle {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            padding: 0;
            h1 {
              font-size: 20px;
              text-align: center;
            }
            h3 {
              font-size: 15px;
            }
          }
          .bgLeftElement {
            height: 180px;
            left: -160px;
            bottom: -140px;
          }
          .bgRightElement {
            height: 80px;
            right: -400px;
            bottom: -60px;
            background-size: 214px 80px;
          }
        }
      }
    }
    .isEditMode {
      .col-lg-4 {
        padding: 1rem 0;
      }
      .mainContent {
        margin: 0 0 4rem;
        padding: 0 !important; // force overwriting the style in minified css important for prod build
        .ApplicationSection {
          padding: 0 0 1rem;
          .AppDatePicker {
            width: 100%;
            select {
              padding-left: 0.9rem;
              &:last-child {
                float: right;
                padding-left: 0;
              }
            }
          }
        }
        .ApplicationReview {
          padding: 0 0 1rem;
          .ApplicationReviewCollection {
            padding: 2rem 0 0;
            .collectionItemFields {
              padding: 1rem;
            }
          }
        }
      }
      .collectionItemFields {
        padding: 0.5rem 0;
      }
    }
    &.view-Welcome, &.view-SchoolReminder {
      .site-header__title {
        display: none;
      }
    }
  }
}

@include old-iphone {
  #app {
    .AppHeader {
      .title {
        letter-spacing: -1px;
      }
    }
  }
}

// override new vuetify vertical neg padding
.v-application .row {
  margin-top: unset;
  margin-bottom: unset;
}

.row + .row {
  margin-top: unset;
}
