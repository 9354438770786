
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ErrorCard {
  background-color: $color-white;
  border-radius: 5px;
  text-align: center;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);

  &__icon-container {
    background-color: $color-third;
    border-radius: 50%;
    width: 185px;
    height: 185px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 100px;
  }

  &__heading {
    font-size: 24px;
    font-weight: bold;
  }

  &__error-code,
  &__actions {
    margin-top: 40px;
  }

  &__info,
  &__error-code,
  &__error-id,
  &__error-ehub {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 0;
  }

  &__info,
  &__error-ehub {
    margin-top: 10px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .ErrorCard__action-button {
      font-size: 16px;
      font-weight: bold;
      padding: 20px;
      margin: 10px 20px;
    }
  }

  &__link {
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px black;
    }
  }

  .bold {
    font-weight: bold;
  }
}
