
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.SchoolInfoCard {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 16px 24px 32px;

  &__title {
    font-weight: bold;
  }

  &__apply-button {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
  }
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
