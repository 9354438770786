
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFieldGroup {
  margin-right: 1rem;
  margin-bottom: 3.5rem;
  margin-left: 1rem;
  padding: 2rem 2rem 0;
  background-color: $color-white;

  .groupField.fieldInline {
    display: inline-block;
    width: auto;
  }
}
