
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationBanner {
  margin-bottom: 120px;
}

.OOASchoolReminderCard {
  margin-top: 3.5rem;
}

::v-deep .SchoolInfoPanels,
::v-deep .SchoolInfoCard {
  margin-top: 3.5rem;
}

::v-deep .v-expansion-panel-content {
  font-weight: 500;
}

::v-deep .local-school__apply-button {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

@include desktop {
  .ApplicationBanner {
    height: 218px;
  }
}

@include tablet-wide {
  .ApplicationBanner {
    height: 218px;
  }
}

@include tablet {
  .ApplicationBanner {
    height: 122px;
    max-height: 122px;
  }
}
