
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppHeader {
  z-index: z('header');
  position: fixed;
  width: 100%;
  border-bottom: solid 1px $color-white;
  background-color: $color-primary;
  color: $color-white;
  font-size: 1rem; // Reset to base
  line-height: 1;

  a.logo {
    display: inline-block;
    &:focus,
    &:active {
      box-shadow: 0 0 0 1px white;
    }
  }

  &.AppHeader--training {
    background-color: $color-training;
    border-color: $color-training;
    border-bottom-color: white;

    .site-header--has-menu .site-header__actions {
      flex: 1;
    }

    .site-header__resume {
      background-color: $color-training-secondary;
    }
  }

  .site-header {
    display: flex;
    align-items: center;
    padding-top: 1.3em;
    padding-bottom: 1.3em;
  }

  .site-header__actions {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .site-header--has-menu {
    .dropdown__button {
      margin-left: 1.5em;
    }
  }

  .site-header__logo {
    height: 4em;
  }

  .site-header--has-menu .site-header__logo {
    margin-left: auto;
  }

  .site-header__title {
    margin-bottom: 0;
    margin-left: 1em;
    margin-right: 1em;
    font-weight: $text-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.site-header__title--full {
      display: none;
    }
  }

  .site-header__menu-trigger {
    // Set a fixed width so as to avoid the text of the title jumping when the
    // icon is swapped between open and close states.
    @include button-reset;
    width: 18px;
  }

  .site-header__status {
    display: none;
    align-items: center;
    margin-left: auto;
    font-weight: $text-bold;
  }

  .site-header__status-icon {
    font-size: 2.5rem;
  }

  .site-header__status--saving .site-header__status-icon {
    animation: statusSpin 3s infinite linear;
  }

  .site-header__status-text {
    margin-left: 1rem;
    font-size: 1.5em;
  }

  .site-header__resume {
    margin-left: auto;
  }

  .site-header__resume-link {
    padding: 0.7rem 1rem;
    border-radius: 4px;
    background-color: $color-red;
    font-weight: $text-bold;
    font-size: $text-small;
    outline: none;
    cursor: pointer;
    transition: $basic-transition;
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: $color-white;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 0 2px $color-white;
    }
  }

  .site-header__resume-icon {
    margin-right: 0.8rem;
    width: 2rem;
  }
}

.text {
  color: $ads-grey-01 !important;
}
// #DSE-1196: Custom MQ, deliberately kept out of the wider styles as it is
// unique to this component alone.
@media (min-width: 720px) {
  .AppHeader .site-header__title {
    display: block;
  }
}

@include desktop {
  .AppHeader {
    z-index: z('menu');
    height: 74px;
    .logo {
      max-width: 42px;
      margin-right: 1rem;
    }
    .title {
      font-size: 17px;
      line-height: normal;
    }

    .site-header__status {
      display: flex;
    }

    .site-header--has-menu {
      flex-direction: row-reverse;

      .dropdown__button {
        margin-left: 0em;
      }

      .site-header__actions {
        flex: 1;
      }
    }

    .site-header {
      flex-direction: row;
    }

    .site-header--has-menu .site-header__logo {
      margin-left: 0;
    }

    .site-header__title {
      font-size: 2.1em;

      &.site-header__title--full {
        display: block;
      }

      &.site-header__title--short {
        display: none;
      }
    }

    .site-header__menu-trigger {
      display: none;
    }
  }
}

@include tablet {
  .dropdown__button {
    margin-left: 1.5rem;
  }

  .site-header--has-menu {
    .site-header__logo {
      display: none;
    }
  }
}

@include tablet-wide {
  .site-header--has-menu {
    .site-header__logo {
      display: none;
    }
  }
}

@include mobile {
  .AppHeader {
    .site-header__title {
      font-size: 1.7em;
      letter-spacing: -1px;
    }

    &--training {
      &.AppHeader--resume .site-header {
        align-items: flex-start;
      }

      .site-header {
        &__logo {
          height: 32px;
        }

        &--has-menu .site-header__actions {
          flex-direction: row;
          align-items: center;
          margin-left: 0;

          .dropdown__button {
            margin-left: 1.5rem;
          }
        }

        &__actions {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1.5rem;
        }

        &__resume {
          margin-top: 1.5rem;
          margin-left: 0;
        }

        .dropdown__button {
          margin-left: 0;
          margin-top: 0;
        }
      }
    }
  }
}

@media print {
  .AppHeader {
    display: none;
  }
}

@keyframes statusSpin {
  100% {
    transform: rotate(360deg);
  }
}
