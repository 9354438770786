
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.actions {
  margin-top: 3rem;
  span {
    font-size: 2rem;
    margin: 1rem;
  }
  button {
    margin: 1rem 0;
  }
}
