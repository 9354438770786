
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.LoginView {
  z-index: 1;
  position: relative;
  padding: 0;
  width: 100%;
  padding-bottom: 40px;
  min-height: 560px;
}

@include desktop {
  .LoginView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }

  .content {
    margin: 0 auto;

    & ::v-deep .code-input,
    & ::v-deep .email-input {
      margin: 0 auto 2rem;
      padding: 2.4rem 2rem;
      min-width: 180px;
      border: 1px solid $color-grey-60;
      border-radius: 0.4rem;
    }

    & ::v-deep h1 {
      font-weight: bold;
      font-size: $text-h1;
      line-height: 48px;
    }

    & ::v-deep p {
      margin: 2rem 0;
      font-size: $text;
    }

    & ::v-deep button {
      margin: 1rem auto 1rem;

      &:focus,
      &:hover {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

@include tablet-wide {
  .LoginView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
  }
}

@include tablet {
  .LoginView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }

    .ApplicationWelcomeEmail {
      & ::v-deep .email-form-row {
        .email-form {
          .form-control.email-input {
            max-width: 100%;
          }

          button {
            top: 0;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

@include mobile {
  .LoginView {
    height: auto;

    .ApplicationWelcomeEmail {
      height: auto;

      .typed-email-headline {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }

      &::v-deep .content.container {
        margin-bottom: 10px;

        button {
          width: 100%;
        }
      }
    }
  }
}

.fade-enter-active {
  transition: all 0.8s ease-in;
}

.fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
