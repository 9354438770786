
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-btn:focus {
  background-color: #fff !important;
}

.text {
  color: $ads-grey-01 !important;
}
.IconDropdown ::v-deep {
  .v-subheader {
    font-size: 1.6rem;
  }

  .v-list-item {
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .v-list-item--active {
    background: transparent;
  }
}
