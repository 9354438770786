
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@include desktop {
  .WelcomeView {
    width: 100%;
    height: 100%;
    min-height: 610px;
    text-align: left;
  }
}
