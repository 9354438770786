
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppAddressFinder {
  margin-top: 0.25rem;
  &::v-deep .labelDescription {
    font-size: 1.6rem;
    font-weight: normal;
  }

  .ApplicationField:first-child {
    margin-bottom: 3.5rem;
  }

  .ApplicationField {
    margin: 0 1rem 1rem 1rem;
    .fieldText {
      width: 100%;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }

    .smallMessage {
      display: inline-block;
      padding-left: 0.25rem;
      padding-top: 0.5rem;
      font-size: 1.4rem;
    }
  }

  .fieldContent-withMarginBottom {
    margin-bottom: 1.5rem;
  }

  .addressFields {
    &.container {
      padding: 0;
      margin-top: 1.5rem;
    }

    select,
    input {
      width: 100%;
    }
  }

  &::v-deep .AppTypeAhead_input {
    width: 100%;
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      color: $color-red;
      position: relative;
      top: -0.5rem;
      height: 3em;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      font-size: 1.4rem;
      color: $color-red;
    }
  }
}

.boxGrey {
  background: $color-grey-10;
  padding: 1rem;
  border-radius: 0.2rem;
  color: $color-primary;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}
