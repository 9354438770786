
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppOverlay {
  z-index: z('overlay');
  position: fixed;
  top: $header-height;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(87, 101, 119, 0.58);
}
