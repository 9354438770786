
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.typed-email-headline {
  color: $color-red;
  line-height: normal;
  font-weight: bold;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
