
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.panel-header {
  opacity: 1;
  padding-top: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;

  &__title,
  &__subtitle {
    color: $color-primary;
  }

  &__title {
    font-size: 2.2rem;
    font-weight: bold;
    margin-right: 2rem;
  }

  &__subtitle {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__info-icon {
    flex: 0 0 auto;
    border: none;
    align-self: flex-start;
    margin: 0;
  }

  ::v-deep .v-input__slot {
    display: flex;
    align-items: flex-start;

    //Checkbox unchecked icon
    .mdi-checkbox-blank-outline {
      color: rgba(0, 0, 0, 0.54) !important;
    }

    //Checkbox label
    .v-label {
      margin-left: 2.5rem;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.v-expansion-panel-header {
  &--active {
    background-color: inherit;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }

  .jt-vhtml-description ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }
}

.v-expansion-panel-content {
  margin-left: 5.8rem;
  margin-right: 2.5rem;
}

::v-deep .v-expansion-panel-content__wrap {
  padding: 0 14px 16px;
}

::v-deep .v-expansion-panel-content__wrap {
  padding-right: 16px;
}
