
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.login-card {
  &.white-card {
    min-height: 24rem;
    display: flex;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon-container {
    display: flex;
    justify-content: center;
    flex: 1 8 12rem;
    margin-right: 4rem;
    margin-left: 0.5rem; /* different by 3.5rem to right to compensate for bootstrap padding */
    & > img {
      width: 12rem;
    }
  }

  &__body-container {
    flex: 8 1 60rem;
  }

  button {
    margin-left: 4rem;
    &.disabled {
      background-color: #666;
      border-color: #666;
      cursor: not-allowed;
    }
  }

  h2 {
    font-weight: $text-bold;
  }
}

@include tablet-wide {
  .login-card {
    button {
      margin-left: 2rem;
    }
  }
}

@include tablet {
  .login-card {
    &__content {
      flex-direction: column;
      justify-content: center;
    }

    &.white-card {
      height: auto;
    }

    &__icon-container {
      align-self: flex-start;
      margin: 0rem 0 3rem 0;
      flex: none;
      & > img {
        width: auto;
      }
    }

    &__body-container {
      flex: none;
    }

    p,
    h2,
    input {
      max-width: none;
    }

    button {
      margin-left: 0;
    }
  }
}

@include mobile {
  .login-card {
    button {
      width: 100%;
    }

    &__icon-container {
      align-self: center;
    }
  }
}
