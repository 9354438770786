
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.login-options {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  &__card-separator {
    color: $color-primary;
    font-weight: 700;
    line-height: 1.2;
    margin: 3rem 0;
    text-align: center;
    font-size: 2.2rem;
  }

  .content {
    margin: 0 auto;

    &.container {
      padding: 40px 15px 15px;
    }
  }
}
