
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ErrorContent {
  .error {
    &__heading {
      margin-bottom: 2rem;
    }

    &__heading,
    &__heading * {
      font-weight: bold;
      color: $color-primary;
    }

    &__body {
      margin-bottom: 2rem;
    }

    &__heading,
    &__heading *,
    &__body,
    &__body *,
    &__footer,
    &__footer * {
      font-size: 2rem;
    }

    &__stack-trace {
      border: 1px solid $color-grey-15;
      border-radius: $card-border-radius;
      color: $color-primary;
      background-color: white;
      padding: 1.6rem 1.6rem 0.6rem;
      margin-top: 3rem;
      font-size: $text;
    }

    &__link {
      &:focus,
      &:active {
        box-shadow: 0 0 0 1px black;
      }
    }
  }
}
