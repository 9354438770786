
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.FileUpload {
  margin-top: 1rem;
  background-color: $color-white;
  color: $color-text-body;

  ::v-deep button {
    border: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.file-chip {
  padding-right: 31px;

  &--removable {
    padding-right: 0;
  }
}

.progress-wrapper {
  height: 4px;
}

.description {
  margin-bottom: 1rem;
}

//Vuetify components
::v-deep {
  .v-messages__wrapper {
    margin-left: -10px;
    font-size: 14px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .v-text-field__details {
    height: 22px;
  }

  // /*This CSS is to resolve the bug with the upload component in safari:
  //  https://github.com/vuetifyjs/vuetify/issues/10832
  //  This bug was resolved in this fix:
  //  https://github.com/vuetifyjs/vuetify/commit/d5800aad7dc9e62e7d398c890b7af6580e6060ce
  //  as part of v2.3.11. However, due to circumstances at the time the bug was found
  //  we were unable to do a vuetify update and so are implementing the fix ourselves.
  //  This should be removed once vuetify is updated to this version or higher.
  //  */
  .v-file-input input[type='file'] {
    pointer-events: none;
  }
}
