
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ErrorView {
  height: 100%;
}
.error-content {
  padding: 5rem 1.5rem;
}

@include desktop {
  .ErrorView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
    .Content {
      max-width: 1110px;
    }
  }
}

@include tablet-wide {
  .ErrorView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
    }
    .error-row {
      max-width: 940px;
    }
  }
  content {
    max-width: 700px;
  }
}

@include tablet {
  .ErrorView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }

    .content {
      padding-top: 2rem;

      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
  .Content {
    max-width: 520px;
  }
}

@include mobile {
  .ErrorView {
    .content {
      padding-top: 2rem;

      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
  .Content {
    max-width: 420px;
    padding: 1rem 2rem;
  }
}
