
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationReviewPanels {
  .panel {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    padding: 2rem;
    background: #fafafa;

    &__header {
      font-size: 2rem;
      color: $color-primary;
      font-weight: 700;
      padding: 0.25rem 0;
      border-bottom: solid 1px #b3b3b3;
    }
  }

  .empty {
    font-weight: $text-normal;
    font-size: 2rem;
    color: $color-grey-60;
    margin: 2.5rem 0;
  }
}
