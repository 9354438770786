
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppModal {
  z-index: z('modal');
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);

  .wrapper {
    position: relative;
  }
  .wrapper--small {
    max-width: 52rem;
  }

  .box,
  .actions {
    margin: 2rem;
  }

  .box,
  .closeButton {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
  }

  .box {
    z-index: z('base');
    overflow-y: auto;
    position: relative;
    padding: $content-padding;
    max-width: 860px;
    max-height: 80vh;
    border-radius: 0.5rem;
    background-color: $color-white;
  }

  .body {
    color: $color-text-body;
    font-size: 1.4rem;
  }

  .actions {
    z-index: z('base') + 1;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }

  .closeButton {
    margin: 0;
    width: 4rem;
    height: 4rem;
    border: none;
    border-radius: 100%;
    background-color: $color-primary;
    color: $color-white;
    font-size: 3rem;
    line-height: 3.2rem;

    &:hover {
      text-decoration: none;
    }
  }
}

// Classes auto generated by Vue transition
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
