
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFieldCollectionHeading {
  padding: $content-padding;

  h3 {
    margin: 0 0 3.5rem;
    display: flex;
    border-bottom: solid 1px $color-grey-30;
    span {
      &:focus {
        outline: revert !important;
      }
      font-size: $text-h5;
      color: $color-primary;
      font-weight: $text-bold;
      flex: 1;
      margin: 0.25rem 0;
    }
    .remove {
      color: $color-primary;
      font-weight: normal;
      font-size: $text;
      font-weight: 300;
      padding: 0;
      border: none;
      background: transparent;
    }
  }
  &.canAddRemove h3 {
    padding: 0 0 0.15rem;
    min-height: 3.2rem;
  }
  &.showLine h3 {
    border-bottom: 2px solid;
    padding: 0 0 0.5rem;
  }
  &.collapsible {
    h3 {
      border-bottom: none;
      margin: 0;
    }
    .remove {
      @include button-reset;
    }
  }
}

#app.v-application
  .ApplicationFieldCollectionHeading.v-expansion-panel-header.v-expansion-panel-header--active {
  background-color: inherit;
}
