
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.LogoutlView::v-deep {
  height: 100%;
  color: $color-primary;
  h1 {
    text-align: center;
  }

  .ApplicationBanner {
    margin-bottom: 10rem;
  }
  .container {
    max-width: 97rem;
  }
  .Content {
    margin: 2rem auto;
    padding: 2rem 2rem;
    &__Title {
      margin-bottom: 4rem;
      padding: 0 5.5rem;
    }
    .content-box {
      padding-bottom: 1rem !important;
    }
    p {
      font-size: 1.6rem;
    }
  }
  .actions {
    margin-top: 3rem;
  }
}

@include desktop {
  .LogoutlView::v-deep {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
      .bannerTitle {
        max-width: 80rem;
        margin: 0 auto;
      }
      .container {
        max-width: 97rem;
      }
    }
    .Content {
      max-width: 900px;
      margin: 0 auto;
    }
  }
}

@include tablet-wide {
  .LogoutlView {
    .ApplicationBanner {
      height: 204px;
      max-height: 204px;
      ::v-deep .container {
        max-width: 720px;
      }
    }
    .error-row {
      max-width: 940px;
    }
  }
  .Content {
    max-width: 720px;
    span {
      display: block;
    }
  }
}

@include tablet {
  .LogoutlView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
      ::v-deep .container {
        max-width: 540px;
      }
    }

    .Content {
      padding-top: 2rem;
      max-width: 540px;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
}

@include mobile {
  .LogoutlView {
    .Content {
      padding: 2rem;
      max-width: 420px;
      .Content__Title {
        padding: 0 1rem;
      }
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
}
