
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationView {
  margin: 0;
  .banner__title {
    font-size: 1.6rem;
  }
  &.isEditMode {
    .ApplicationBanner {
      margin-bottom: $header-height;
      height: 102px;
      max-height: 102px;
      position: relative;
      padding: 15px 15px 10px 15px;
      .headline {
        .bannerTitle {
          position: absolute;
          transform: none;
          bottom: 0;
          top: auto;
        }
        .bgLeftElement {
          height: 360px;
          width: 360px;
          left: -300px;
          bottom: -190px;
        }
        .bgRightElement {
          height: 196px;
          width: 523px;
          right: -190px;
          bottom: -90px;
        }
      }
    }
  }
}

.dialog {
  &__title {
    font-size: 20px;
    font-weight: 700;
  }
  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__content {
    font-size: 16px;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
@include desktop {
  #app {
    .isEditMode {
      .ApplicationBanner {
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
          }
          h1 {
            font-size: 40px;
          }
          .bgLeftElement {
            left: -344px;
            bottom: -292px;
          }
          .bgRightElement {
            right: -210px;
            bottom: -120px;
          }
        }
      }
      .content {
        width: 100%;
        height: 100%;
      }

      .sideContent {
        position: relative;
        top: 2rem;
        display: block;
        min-height: 100%;
        min-width: 325px;
      }

      .mainContent {
        width: calc(100% - #{$desktop-sidebar-width});
      }

      .isEditMode .sideContent {
        // Extra padding for when save bar is visible, so bottom content can be reached
        padding-bottom: $save-bar-height;
      }

      .isEditMode .mainContent {
        padding-bottom: $save-bar-height;
      }
    }
  }
}

@include tablet-wide {
  #app {
    .isEditMode {
      .ApplicationBanner {
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
          }
          .bgLeftElement {
            left: -240px;
            bottom: -180px;
          }
          .bgRightElement {
            right: -330px;
            bottom: -50px;
          }
        }
      }
    }
    .container {
      .sideContent {
        padding: 4rem 0 0;
      }
      .mainContent {
        padding: 0;
      }
    }
  }
}

@include tablet {
  #app {
    .isEditMode {
      .ApplicationBanner {
        height: 82px;
        max-height: 82px;
        .headline {
          .bannerTitle {
            position: absolute;
            transform: none;
            bottom: 0;
            top: auto;
            h1 {
              font-size: 28px;
            }
          }
          .bgRightElement {
            right: -350px;
            bottom: -70px;
          }
          .bgLeftElement {
            left: -180px;
            bottom: -160px;
          }
        }
      }
      .container {
        .sideContent {
          padding: 3rem 0 0 2rem;
        }
        .mainContent {
          padding: 0 2rem;
        }
      }

      .banner {
        padding-left: 24px !important;
        padding-right: 24px !important;
      }
    }
  }
}

@include mobile {
  #app {
    .isEditMode {
      .ApplicationBanner {
        height: 68px;
        max-height: 68px;
        margin: 0 -15px 75px -15px;
        .headline {
          .bannerTitle {
            left: 10px;
            transform: none;
            h1 {
              font-size: 21px;
              padding: 0 10px;
              overflow-wrap: break-word;
              word-wrap: break-word;
            }
          }
          .bgLeftElement {
            height: 130px;
            left: -115px;
            bottom: -90px;
          }
          .bgRightElement {
            height: 90px;
            right: -400px;
            bottom: -48px;
          }
        }
      }

      margin: 0 15px;
      .container {
        .sideContent {
          padding: 0;
        }

        .mainContent {
          form {
            &.ApplicationSection {
              & ::v-deep .ApplicationSaveCancel {
                padding: 0;

                button {
                  &.primary {
                    margin: 0.5rem 0 0;
                  }
                }
              }
            }
          }
        }
      }
      .banner {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
  }
}
