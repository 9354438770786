
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content-box {
  margin: 0px 13px;
  padding-bottom: 60px !important; //!important required to override !important on vuetify styles
}

.mobile .content-img {
  margin-bottom: 12px;
}
.content-img img {
  width: 70px;
  height: 70px;
}
.content-img img.largeImage {
  width: 88px;
  height: 88px;
}
.content-img.rightSpacing {
  margin-right: 40px;
}
.largeImage .content-img.rightSpacing {
  margin-right: 31px;
  margin-left: -9px;
}
.content-body {
  width: 100%;
}

@include desktop {
  .content-box {
    padding-right: 60px !important; //!important required to override !important on vuetify styles
  }
}
