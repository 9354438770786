
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationReviewSection {
  padding-top: $content-padding * 1.25;
  padding-bottom: $content-padding * 1.25;

  .sectionHeader {
    cursor: pointer;
    display: flex;
    align-items: center;

    > h3 {
      display: inline-block;
      margin: 0;
      font-weight: $text-normal;
      font-size: 2rem;
      vertical-align: middle;
      flex: 1 1 auto;

      .icon--square {
        width: 30px;
        height: 30px;
        flex: 0 0 30px;
        border-radius: 3px;
        font-weight: 700;
        font-size: 2rem;
        text-align: center;
        line-height: 30px;
      }

      > button {
        @include button-reset;

        display: flex;
        align-items: center;
        .icon {
          margin-left: 1rem;
        }
        &.error {
          color: $color-red;
          background-color: transparent !important;
        }
      }
    }

    > button {
      margin: 0;
      height: 30px;
      border: none;
      font-weight: 300;
      font-size: 1.6rem;
      margin-left: auto;
      flex: 0 0 120px;
    }
  }
}
@include mobile {
  .ApplicationReviewSection {
    .sectionHeader {
      h3 {
        width: 170px;
        button {
          text-align: left;
        }
      }
    }
  }
}

@include iphone-5 {
  .ApplicationReviewSection {
    .sectionHeader {
      h3 {
        width: 123px;
        button {
          font-size: 15px;
        }
      }
      > button {
        font-size: 15px;
      }
    }
  }
}
