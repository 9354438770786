
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.SchoolInfoPanels {
  font-size: 1.6rem;
}

.addressHeader {
  padding: 8px;
  min-height: 72px;
  height: auto;
  background: $color-third;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  align-items: center;
  color: $color-text-body;
  font-size: 18px;
  font-weight: 500;
}

.v-expansion-panel-content {
  font-weight: 500;
}

.v-expansion-panel-header--active {
  background-color: $color-light-shade;
}

.local-school__apply-button {
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
}

@include desktop {
  .addressHeader {
    padding: 0;
  }
}
