
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationSection {
  padding: $content-padding;

  word-wrap: break-word;
  overflow-wrap: break-word;

  .sectionFields {
    padding: 0 0 $content-padding;
  }

  .message {
    display: flex;
    align-items: top;
    margin: $content-padding;
    padding: $content-padding;
    border: 1px solid $color-warning-border;
    border-radius: $field-border-radius;
    background-color: $color-warning;
    font-size: 1.6em;

    .icon {
      margin: 1.8rem 0.4em 0 0.6rem;
      padding: 0;
      width: 100%;
      max-width: 46px;
      font-size: 28px;
      color: $color-red;
    }

    span {
      display: block;
      padding: 1.4rem 0 0;

      button {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;
        color: $color-secondary;
        font-weight: normal;
        outline: none;

        &.additionalButton {
          font-weight: $text-medium;
        }

        &:focus {
          outline-style: auto;
        }
      }
    }
  }
}
