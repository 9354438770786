
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-alert__icon.v-icon {
  font-size: 27px;
  height: 27px;
  min-width: 27px;
}
div.v-snack {
  width: auto;
  margin: auto;
  ::v-deep .v-snack__content {
    padding: 25px;
  }
  &.v-snack--bottom {
    left: 20px;
    bottom: 20px;
  }
}
::v-deep .v-btn--icon.v-size--default {
  height: 30px;
  width: 29px;
  padding: 0;
}
#persistent--element1 .v-snack {
  bottom: 20px !important;
}
#persistent--element2 .v-snack {
  bottom: 170px !important;
}
#persistent--element3 .v-snack {
  bottom: 320px !important;
}
#persistent--element4 .v-snack {
  bottom: 470px !important;
}
#persistent--element5 .v-snack {
  bottom: 620px !important;
}
.alert--clickable-action {
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: bold;
}
.theme--light {
  .alertsuccess {
    background: #def3d8 !important;
  }
  .alertwarning {
    background: #fae8dd !important;
  }
  .alertinfo {
    background: #e0e9ff !important;
  }
  .alerterror {
    background: #f5e1e3 !important;
  }
  div.v-snack {
    .snackbar--text {
      color: #333333;
      font-weight: bold;
    }
    .alert--text {
      color: #333333;
      font-weight: bold;
    }
    .alert--textsimple {
      color: #333333;
    }
  }
}
.theme--dark {
  .v-snack,
  .v-alert {
    .alertsuccess {
      background: #333333 !important;
    }
    .alertwarning {
      background: #333333 !important;
    }
    .alertinfo {
      background: #333333 !important;
    }
    .alerterror {
      background: #333333 !important;
    }
  }
}

.blue-alert {
  background: $color-third;
}

.customStyle {
  font-size: 13px;
}

.customAlertStyle {
  margin-left: -10px;
  padding: 24px 18px;
}
