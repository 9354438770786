
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFieldReadOnly {
  margin-top: $content-padding * 2.5;
  margin-bottom: $content-padding * 2.5;
  &.hasAlert {
    border-left: 0.5em solid $color-red;
    padding-left: 0.5rem;
  }
  .alertRow {
    display: flex;
    padding-top: 0.5rem;
    svg {
      height: 2em;
    }
  }
  .alertMessage {
    display: inline-block;
    padding-left: 0.25rem;
    font-size: $text-small;
    color: $color-red;
    ::v-deep .link {
      color: $color-red;
    }
  }
  .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;
  }
  .label {
    font-size: 1.4rem;

    .required-mark {
      float: left;
    }
  }
  .value,
  ::v-deep .value {
    font-weight: $text-normal;
    font-size: 2rem;
  }
  .value-empty,
  ::v-deep .disabled:not(.label) {
    color: $color-grey-60;
  }

  ::v-deep .labelDescription {
    color: $color-grey-60;
    font-size: 1.2rem;
  }

  ::v-deep .ApplicationFieldHeading {
    padding: 0;
  }

  .document {
    &__value {
      display: flex;
      align-items: flex-start;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
