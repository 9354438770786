
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.input-group {
  & ::v-deep .AppTypeAhead {
    width: 100%;
  }

  & ::v-deep .AppTypeAhead_input {
    width: 100%;
    margin-right: 0;
    @include vuetify-input;
  }
}

.btn-search {
  width: 100%;
  margin: 0;
  height: 5.6rem;
  padding: 0.9rem 1rem;
  &:disabled {
    border-color: $color-text-light;
    background-color: $color-text-light;
    cursor: not-allowed;
  }
}

.ValidationMessage {
  display: inline-block;
  padding: 1rem 0 0;
  .alert {
    padding: 1.25rem 0;
    font-weight: 500;
    font-size: 1.6rem;
    background: none;
    border: none;
  }

  .alert--success {
    color: $color-green-3;
  }

  .alert--danger {
    color: $color-red;
  }

  .alert--warning {
    color: $color-orange-2;
  }

  .alert__icon {
    display: inline-block;
    font-size: 3rem;
    margin: 0 1rem 0 0;
    vertical-align: top;
  }

  .alert__content {
    display: inline-block;
    width: calc(100% - 4rem);
  }

  .alert__link {
    color: $color-red;
    text-decoration: underline;
    transition: $basic-transition;

    &:hover {
      outline: none;
    }

    &:focus {
      outline-style: auto;
    }
  }
}

.catchmentFinder__label {
  @include vuetify-label;
}

.icon {
  &.spin {
    margin-right: 1rem;
  }
}
