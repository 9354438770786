
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;

  p {
    margin-top: 1rem;
    margin-bottom: 0;
  }
}
