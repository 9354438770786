
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.email-form {
  margin-top: 3rem;

  input {
    @include vuetify-input;
    display: inline-block;
    margin: 0 0 15px 0;
    padding: 0 2rem;
    line-height: 3.5rem;
    height: 4.8rem;

    &.has-error {
      border-color: $color-red;

      &:focus {
        box-shadow: 0 0 0.5rem 0.2rem rgba($color-red, 0.24);
      }
    }

    &.has-success {
      border-color: green;

      &:focus {
        box-shadow: 0 0 0 0.3rem rgba($color-green, 0.24);
      }
    }
  }

  &__label {
    @include vuetify-label;
  }

  button {
    position: relative;
    top: -4px;
  }
}

::v-deep .login-card__icon-container {
  align-self: flex-start;
}

.ValidationMessage {
  p {
    &.alert-message {
      position: relative;
      padding-left: 35px;
      color: $color-red;

      .icon {
        position: absolute;
        font-size: 24px;
        left: 0;
        top: -1px;
      }

      .paper-enrolment {
        color: inherit;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

@include tablet {
  .email-form {
    flex-direction: column;
    align-items: center;

    &__continue-btn.btn {
      display: block;
      margin-left: auto;
      top: 4px;
    }
  }

  .ValidationMessage {
    height: auto;
    margin: 0;
  }

  .g-recaptcha {
    transform-origin: 0;
    transform: scale(0.82); //82% scales it down to ~250px
    width: 250px;
  }
}

@include mobile {
  ::v-deep .login-card__icon-container {
    align-self: center;
  }
}
