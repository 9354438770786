
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

$box-size: 2.5rem;
$offset-left: 0.6rem;

::-ms-check {
  // Prevents IE from displaying a black square with
  // a white background abehind radio buttons.
  border: 0px;
  background-color: transparent;
}

.AppRadioButton {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 0.5em 0 0 0;
  margin: 0.25em 1em 0.25em 0;
  min-height: $box-size;
  user-select: none;
  font-size: 1.6rem;

  &.disabled {
    cursor: default;
    opacity: 0.75;
  }

  span {
    padding-left: 3.4rem + $offset-left;
    margin: 0;
    display: inline-block;

    &:before {
      // Circle image
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Ccircle fill='%23313131' cx='16' cy='16' r='16'/%3E%3Ccircle fill='%23fff' cx='16' cy='16' r='14'/%3E%3C/svg%3E");
    }

    &:before,
    &:after {
      transition: $basic-transition;
      width: $box-size;
      height: $box-size;
      content: ' ';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      left: $offset-left;
      top: $offset-left;
    }
  }

  input {
    position: absolute;
    top: $offset-left;
    left: $offset-left;
    height: $box-size;
    width: $box-size;
    border: 0;
    padding: 0;
    border-radius: 50%;
    &:focus {
      box-shadow: none;
      // Shows fake focus ring around the edge of the radio button
      border: 2px solid;
      transform: scale(1.15);
    }
  }
  span:after {
    border-radius: 50%;
    transform: scale(0.01);
    opacity: 0;
  }
  input:checked + span:after {
    // Blue circle selection image
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCAxNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZTMyNWU7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5jaXJjbGUtYmx1ZV8xPC90aXRsZT48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMS0yIiBkYXRhLW5hbWU9IkxheWVyIDEiPjxjaXJjbGUgY2xhc3M9ImNscy0xIiBjeD0iNyIgY3k9IjciIHI9IjciLz48L2c+PC9nPjwvc3ZnPg==');
    transform: scale(0.54);
    opacity: 1;
  }

  &.checkbox {
    span {
      &:before {
        // Box image
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
      }
    }
    input:checked + span:after {
      // Tick image
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23313131' d='M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z'/%3E%3C/svg%3E");
    }
  }
  .textWrapper {
    border: 1px solid $ads-dark-60;
    border-radius: 4px;
    padding: 1.5rem 0.5rem;
    display: block;
    font-family: Montserrat, sans-serif;

    @include mobile {
      padding: 1.5rem 2rem 1.5rem 0;
    }
  }
  .textName {
    font-size: 18px;
    font-weight: 700;
    color: $color-primary;
  }

  .textProviders {
    font-size: 16px;
    color: $ads-dark-80;
    font-weight: bold;
    font-weight: 700;
    padding-bottom: 6px;

    @include mobile {
      font-size: 14px;
    }
  }
  .textDescription {
    font-size: 16px;
    color: $ads-dark-80;
    font-weight: 500;

    @include mobile {
      font-size: 14px;
    }
  }
}

.heading {
  font-size: 18px;
  font-weight: bold;
  color: $color-primary;
  margin-top: 2rem;
  display: block;
}
.isRadioGroup {
  span {
    &:before,
    &:after {
      transition: $basic-transition;
      width: 16px;
      height: 16px;
      content: ' ';
      display: block;
      position: absolute;
      left: 2rem;
      top: 3rem;

      @include mobile {
        left: 1.5rem;
      }
    }
  }

  input {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 2rem;
    top: 3rem;
    border-radius: 50%;
    &:focus {
      box-shadow: none;
      // Shows fake focus ring around the edge of the radio button
      border: 2px solid;
      transform: scale(1.15);
    }

    @include mobile {
      left: 1.5rem;
    }
  }
}

.checkedRadioGroup {
  background-color: $color-third;
  border: 2px solid $color-primary !important;
}

.checkedFontColour {
  color: $color-primary !important;
}
.radioGroupWrapper {
  display: grid;
}
