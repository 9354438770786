
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.CancelView {
  height: 100%;
  .ApplicationBanner {
    margin-bottom: 10rem;
  }
  ::v-deep .container {
    max-width: 97rem;
  }
  .Content {
    margin: 2rem auto;
    padding: 2rem 2rem;
    p,
    span {
      font-size: 2rem;
    }
    span {
      margin: 1rem;
    }
  }
}

@include desktop {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      .container {
        max-width: 97rem;
      }
    }
    .Content {
      max-width: 100rem;
      margin: 0 auto;
    }
  }
}

@include tablet-wide {
  .CancelView {
    .ApplicationBanner {
      height: 20.4rem;
      max-height: 20.4rem;
      ::v-deep .container {
        max-width: 72rem;
      }
    }
    .error-row {
      max-width: 94rem;
    }
  }
  .Content {
    max-width: 72rem;
    span {
      display: block;
    }
  }
}

@include tablet {
  .CancelView {
    .ApplicationBanner {
      height: 12.2rem;
      max-height: 12.2rem;
      ::v-deep .container {
        max-width: 54rem;
      }
    }

    .Content {
      padding-top: 2rem;
      max-width: 54rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 3.2rem;
      }
    }
  }
}

@include mobile {
  .CancelView {
    .Content {
      padding: 2rem;
      max-width: 42rem;
      span {
        display: block;
      }
      & ::v-deep h1 {
        font-size: 2.6rem;
      }
    }
  }
}
