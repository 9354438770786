
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#app {
  .ConfirmationView {
    margin: 0;
    padding: 0 0 40px;
    .ApplicationBanner {
      height: 102px;
      padding: 0px 15px;
      .container {
        .bannerTitle {
          position: absolute;
          transform: none;
          bottom: 10px;
          top: auto;
        }
      }
    }
    .ConfirmationContent {
      margin-top: 80px;
    }

    h4 {
      font-weight: normal;
      font-size: 2rem;
    }

    p,
    li {
      font-size: 1.4rem;
    }

    p {
      margin-bottom: 0;
    }

    ul {
      > li + li {
        margin-top: $content-padding;
      }
    }

    hr {
      border: 1px solid #e4e4e6;
    }
    a:focus {
      outline: 1px solid $color-primary;
    }
    .alert {
      padding: 1rem;
      border: 1px solid;
      border-radius: 0.2rem;
      color: #212529;
    }

    .alert-success {
      padding-top: $content-padding * 2;
      padding-bottom: $content-padding * 2;
      border-color: #a2d8ae;
    }

    .alert-warning {
      border-color: $color-warning-border;
      background-color: $color-warning;
    }

    .detail {
      display: flex;
      margin-right: 5px;

      .detail__icon {
        flex: 0 0 7rem;
        height: 7rem;
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;

        &--email {
          background-image: url(../assets/icon-email.svg);
        }

        &--papers {
          background-image: url(../assets/icon-documents.svg);
        }

        &--survey {
          background-image: url(../assets/icon-clipboard.svg);
        }

        &--hub {
          background-image: url(../assets/icon-hub.svg);
        }
      }

      .detail__body {
        margin-left: 3rem;
      }
    }

    .ConfirmationContent__Summary {
      display: flex;
      padding: 50px 0px 50px 50px;

      .ConfirmationContent__Success-icon {
        flex: 0 0 7rem;
        height: 7rem;
        background-position: center top;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/icon-success-green.svg);
      }

      .ConfirmationContent__body {
        margin-left: 3rem;
      }
    }

    .bannerTitle {
      position: absolute;
      transform: none;
      bottom: 0;
      top: auto;
      h1 {
        font-size: 40px;
      }
    }
  }
}

.link-outline {
  &:focus {
    outline-style: auto;
  }
}

@include desktop {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        h1 {
          font-size: 40px;
        }
        .bgLeftElement {
          left: -344px;
          bottom: -282px;
        }
        .bgRightElement {
          right: -210px;
          bottom: -110px;
        }
      }
    }
  }
}
@include tablet-wide {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        h1 {
          font-size: 40px;
        }
        .bgLeftElement {
          left: -344px;
          bottom: -282px;
        }
      }
    }
  }
}
@include mobile {
  #app {
    .ConfirmationView {
      .ApplicationBanner {
        height: 68px;
        max-height: 68px;
        .headline {
          .bannerTitle {
            left: 0;
            h1 {
              padding: 0 15px;
            }
          }
          .bgLeftElement {
            height: 130px;
            left: -115px;
            bottom: -80px;
          }
          .bgRightElement {
            height: 90px;
            right: -400px;
            bottom: -38px;
          }
        }
      }
    }
  }
}
