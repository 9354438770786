
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.notification {
  position: relative;
  margin-bottom: 0.8rem;

  &-container {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    z-index: 3000;
    bottom: 0;
    left: 1rem;
    margin-right: 1rem;
  }

  ::v-deep .v-snack__content {
    border-color: transparent;
  }

  &--error ::v-deep .v-snack__content {
    border-color: #b81237;
  }

  &--success ::v-deep .v-snack__content {
    border-color: #00a908;
  }

  &--info ::v-deep .v-snack__content {
    border-color: #2e5299;
  }

  &--warning ::v-deep .v-snack__content {
    border-color: #dc5800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__body {
    margin-right: 2rem;
  }

  &__header {
    align-items: center;
    min-height: 36px;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__actions {
    font-size: 1.6rem;
    display: flex;

    a {
      text-decoration: none;
    }

    > :last-child {
      margin-left: 3rem;
    }
  }

  &__icon {
    margin-top: 0.7rem;
    margin-right: 1.6rem;
  }
}
::v-deep .v-snack {
  &__content {
    align-items: flex-start;
    padding-bottom: 1rem;
    border-left: 8px solid;
    border-radius: 4px;
  }
}

h1 {
  font-size: 1.6rem;
  margin-bottom: 0;
}

@media (max-width: 580px) {
  .notification__actions {
    flex-direction: column;
    > :last-child {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}

::v-deep .v-snack__content .notification__header .notification__close-btn {
  margin-left: auto;
}
::v-deep .v-snack__content .notification__actions button {
  margin-left: 0;
}
