
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

$sideBarWidth: 200px;

.ApplicationWelcome {
  padding: 0 0 30px;
  .content.container {
    padding: 94px 15px 0;
    .WelcomeCopy {
      h2 {
        line-height: 28px;
      }
    }
    .search-address-row {
      position: relative;

      form {
        padding: 6px 0 0;
      }

      h2 {
        padding: 0 0 5px;
        font-size: 21px;
      }
    }

    .intro {
      text-align: center;
      padding: 15px 30px 5px;
    }

    .intro-desc {
      h2 {
        padding-bottom: 0;
        margin-bottom: 10px;
        padding-right: 20px;
      }
    }
  }

  ul {
    padding: 0 0px 0 15px;
    font-size: $text;

    &.horizontal-list {
      padding: 40px 0 5px 5px;
      list-style: none;

      li {
        position: relative;
        padding: 0 0 35px 120px;
        line-height: 26px;
        p {
          line-height: 26px;
        }
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 91px;
          height: 91px;
        }
      }

      h2 {
        padding: 0 15px 5px 0;
      }
    }
  }

  h1 {
    &.invalid-code-msg {
      padding: 30px 0;
    }
  }

  h2 {
    line-height: 28px;
    padding: 2rem 0;
  }

  .container {
    text-align: left;

    hr {
      margin: 2rem 0 0;
    }

    .intro-icon {
      height: auto;
    }

    .slide-fade-enter-active {
      transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
      transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
      opacity: 0;
    }
  }

  .Note {
    padding: 40px 20px 20px;
  }
}

.AppCatchmentFinder {
  margin-top: 2rem;
}

.ads-secondary-button {
  color: #041e42;
  border-width: 2px;
}

::v-deep .link-outline {
  &:focus {
    outline-style: auto;
  }
}

@include desktop {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-desc {
        padding: 0 25px;
      }

      .intro-icon {
        max-width: 180px;
      }
    }

    .Banner {
      h1 {
        font-size: 51px;
        line-height: 50px;
      }

      h3 {
        font-size: 26px;
        padding-top: 8px;
      }
    }
  }
}

@include tablet-wide {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 218px;
    }
    .container {
      .intro-icon {
        max-width: 170px;
      }
    }
  }
}

@include tablet {
  .ApplicationWelcome {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
    .content {
      margin-bottom: 20px;
      padding: 1.2rem 1.2rem 2rem;

      .search-address-row.white-card {
        h2,
        p {
          text-align: center;
        }
      }

      .intro-icon {
        max-width: 50%;
      }
    }
  }
}

@include mobile {
  .ApplicationWelcome {
    .content {
      &.container {
        margin-bottom: 20px;
        .WelcomeCopy {
          h2 {
            padding: 2rem 4rem;
            text-align: center;
          }
        }

        .intro-icon {
          min-width: 140px;
          width: 140px;
        }

        .intro-desc {
          padding: 0 20px;

          h2 {
            padding: 2rem 2rem;
            text-align: center;
          }

          p {
            text-align: center;
          }
        }

        .horizontal-list {
          li {
            padding: 120px 20px 35px;
            text-align: center;

            &::before {
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }

        .search-address-row {
          h2,
          p {
            text-align: center;
          }
        }
      }
    }

    .Note {
      padding: 0 20px 20px !important;
    }
  }
}
