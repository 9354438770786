
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationLoginOtp {
  position: relative;
  top: $header-height;
  margin-bottom: 3rem;
  padding-bottom: 3rem;

  .content {
    margin: 0 auto;
    padding: 30px;

    &.container {
      padding: 40px 15px 15px;
    }
    h2 {
      &.code-msg {
        padding: 1rem 0 2rem;
        font-weight: $text-medium;
      }
    }
    .no-code {
      padding: 20px 30px 10px;
      .resendCode {
        padding: 0;
      }
    }
    .typed-email-headline {
      color: $color-red;
      line-height: normal;
    }
    .code-form {
      width: 100%;
      .code-input {
        @include vuetify-input;
        width: 100%;
        display: inline-block;
        max-width: 230px;
        margin: 0 20px 0 0;
        &:disabled {
          border-color: $color-red;
          cursor: not-allowed;
          &:focus {
            box-shadow: 0 0 0.5rem 0.2rem rgba($color-red, 0.24);
          }
        }
      }
      button {
        top: -3px;
        position: relative;
        margin: 0;
        &.disabled {
          border-color: $color-grey-60;
          background-color: $color-grey-60;
          cursor: not-allowed;
          &:hover,
          &:focus {
            border-color: $color-grey-60;
            background-color: $color-grey-60;
          }
        }
      }

      &__label {
        @include vuetify-label;
      }
    }
    & ::v-deep .btn-link {
      margin: 0;
    }
  }
  .incorrect-code {
    display: block;
    padding-left: 35px;
    position: relative;
    top: 20px;
    color: $color-red;
    .icon {
      position: absolute;
      font-size: 24px;
      left: 0;
      top: -1px;
    }
    .btn-link {
      color: $color-red;
      padding: 0;
      &:hover {
        color: $color-primary;
      }
    }
  }
  .fade-enter-active {
    transition: all 0.8s ease-in;
  }
  .fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .fade-enter {
    transform: translateY(-5px);
  }
  .fade-leave-to {
    transform: translateY(5px);
  }
}
@include tablet {
  .ApplicationWelcomeOtp {
    .typed-email-headline {
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
@include mobile {
  .content {
    .typed-email-headline {
      line-height: normal;
    }
    .code-form {
      display: block;
      width: 100%;
      .code-input.form-control {
        max-width: 100%;
        margin: 0 0 15px;
      }
      button.btn {
        top: 0;
        width: 100%;
      }
    }
    .verify-code-row {
      .typed-email-headline {
        overflow-wrap: break-word;
        word-wrap: break-word;
      }
    }
    .no-code {
      padding: 10px 30px 0;
    }
  }
}
