
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

button {
  position: relative;
  top: -3px;

  &.disabled {
    background-color: #666;
    border-color: #666;
    cursor: not-allowed;
  }
}

.snsw_content {
  display: flex;
  align-items: center;
}

@include tablet {
  .snsw_content {
    flex-direction: column;
  }

  button {
    align-self: flex-end;
  }

  img {
    content: url('~@/assets/icon-snsw-inline.svg');
  }
}
