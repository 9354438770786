
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationWelcomeBack {
  .content {
    position: relative;
    top: 140px;
    .heading-msg {
      h1 {
        padding: 4rem 0;
        text-align: center;
      }
    }
    .row.ApplyToEnroll {
      text-align: center;
    }
  }
}
