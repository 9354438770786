
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppDatePicker {
  border: $field-border;
  background-color: $color-field-background;
  margin-top: 0.25rem;
  border-radius: $field-border-radius;
  font-size: $text-small;
  display: inline-block;
  select {
    border: 0;
    &.isPlaceholder {
      color: $color-text-light;
    }
  }
}
