
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.PilotView {
  height: 100%;
  .ApplicationBanner {
    height: 218px;
  }
}

@include desktop {
  .PilotView {
    z-index: 1;
    position: relative;
    width: 100%;
    min-height: 400px;

    .content {
      margin: 0 auto;
      padding: 30px;
    }
  }
}

@include tablet-wide {
  .PilotView {
    .content {
      & ::v-deep h1 {
        font-weight: bold;
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
}

@include tablet {
  .PilotView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
      .headline {
        h1 {
          font-size: 28px;
        }
      }
    }
    .content {
      padding-top: 8rem;

      & ::v-deep h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}

@include mobile {
  .PilotView {
    .ApplicationBanner {
      height: 122px;
      max-height: 122px;
    }
    .content {
      padding-top: 8rem;

      & ::v-deep .row {
        .container {
          padding: 30px 12px;
        }
      }

      & ::v-deep h1 {
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
      }

      & ::v-deep button {
        margin: 1rem auto 1rem;
        width: 100%;
      }
    }
  }
}
