
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationField {
  display: inline-block;
  margin-bottom: 3.5rem;
  padding: 0 $content-padding;
  width: 100%;
  vertical-align: top;

  label {
    display: block;
  }

  input,
  textarea {
    &:read-only {
      background: $color-grey-10;
      color: $color-primary;
    }
  }

  input,
  textarea,
  select {
    width: 100%;

    &.isPlaceholder {
      color: $color-text-light;
    }

    &:disabled {
      background: $color-grey-10;
      color: $color-primary;
    }
  }

  .fieldContentRadioGroup {
    padding: 1.25rem 2rem 3rem 3rem;
    @include mobile {
      padding: 1.25rem 1.5rem 3rem;
    }
  }
  .fieldContent {
    padding-top: 0.25rem;
    font-size: $text-small;
  }

  ::v-deep .fieldText,
  ::v-deep .fieldSelect {
    width: 100%;

    @include desktop {
      width: 50%;
    }
  }

  button.button-link-to {
    margin: 0;
    padding: 0;
    min-width: 7rem;
    border: 0;
    background: transparent !important;
    color: $color-primary;
    font-size: 1.6rem;
  }

  ::v-deep .fieldLabel {
    display: block;
    margin: 0 0 0.6rem 0;
    color: $color-text-body;
    font-weight: normal;
    font-size: $text-h5;

    > span.required-mark {
      color: $color-red;
    }

    .required-mark {
      float: left;
    }
  }

  &.fieldInline {
    display: inline-block;
    width: auto;
  }
  &.hasAlertChild {
    padding-left: 0 !important;
  }

  &.hasAlert,
  &.hasAlertChild ::v-deep .error-style {
    padding-left: 0.5rem;
    border-left: 0.5em solid $color-red;
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $color-red;
    }
  }

  .alertMessage ::v-deep {
    display: inline-block;
    padding-left: 0.25rem;
    color: $color-red;
    font-size: $text-small;
    .link {
      color: $color-red;
    }
  }

  .icon {
    margin-top: 0.2em;
    margin-right: 0.6em;
    color: $color-red;
    font-size: 2.5rem;
  }

  .smallMessage,
  ::v-deep .smallMessage {
    display: block;
    padding-top: 0.8rem;
    font-size: $text-small;

    ::v-deep > p {
      font-size: $text-small;
    }

    ::v-deep > p:last-child {
      margin-bottom: 0;
    }
  }

  .smallMessage ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }

  .additionalInfoButton {
    margin: 0;
    padding: 0.8rem 0 0;
    border: none;
    background: transparent;
    color: $color-primary;
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: underline;
  }

  ::v-deep .review-only-label {
    @include sr-only;
  }

  .radioGroupLabel {
    font-size: 18px;
    font-weight: bold;
    color: #002040;
  }
}

.boxWhite {
  background: $color-white;
  padding: 1rem;
}

::v-deep .boxGrey {
  background: $color-grey-10;
  padding: 1rem;
  border-radius: 0.2rem;
  color: $color-primary;
  margin-top: 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.textInfo {
  font-size: 14px;
}

::v-deep .sreSeeLink {
  &:focus {
    border: 2px solid $color-primary;
    text-decoration: none;
  }
}

.notification {
  margin-bottom: -2.5rem;
}
