
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationLoginTimeout {
  position: relative;
  top: $header-height;

  .content {
    margin: 0 auto 3rem;
    padding: 1rem 2rem 3rem;
    text-align: center;
    .icon-row {
      position: relative;
      width: 230px;
      height: 230px;
      background: $color-white;
      border-radius: 50%;
      margin: 5rem auto;
      .error-icon {
        width: 134px;
        height: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    h2 {
      font-size: 28px;
    }
    .btn-link {
      font-size: 1.6rem;
      &:focus {
        outline: 3px solid #005fcc;
      }
    }
  }
}
