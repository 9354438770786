
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.notification {
  background-color: white;
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0 0.5rem 1.5rem;
  padding-bottom: 1rem;
  border-left: 8px solid;
  border-radius: 4px;
  border-color: transparent;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  max-width: 600px;
  word-break: break-word;

  &--error {
    border-color: #b81237;
  }

  &--success {
    border-color: #00a908;
  }

  &--info {
    border-color: #2e5299;
  }

  &--warning {
    border-color: #dc5800;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__header,
  &__body {
    display: flex;
    font-size: 1.6rem;
  }

  &__body {
    margin-right: 2rem;
  }

  &__header {
    align-items: center;
    font-weight: bold;
    min-height: 36px;
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__icon {
    margin-top: 0.7rem;
    margin-right: 1.6rem;
  }
}
::v-deep .v-snack {
  &__content {
    align-items: flex-start;
    padding-bottom: 1rem;
    border-left: 8px solid;
    border-radius: 4px;
  }
}
