
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationFieldHeading {
  padding: $content-padding;
  &__title {
    font-size: $text-h5;
    font-weight: normal;
    color: $color-primary;
  }
  .highlightedDescription {
    background: $color-grey-10;
    padding: 1rem;
    border-radius: 0.2rem;
    color: $color-primary;
    margin-top: 1rem;
  }
  .required-mark {
    color: $color-red;
  }

  h2 {
    margin: 0;
    padding: 6rem 0 1em 0;
    font-weight: $text-bold;
    font-size: $text-h2;
  }

  p {
    font-size: $text-h6;
  }

  .description,
  .description ::v-deep p {
    font-size: 1.8rem;
  }

  .description ::v-deep .link-outline {
    &:focus {
      outline-style: auto;
    }
  }

  .smallMessage {
    display: block;
    padding-top: 0.5rem;
    font-size: 1.8rem;
  }
}
