
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.Page {
  padding: 60px 0 20px;
  //owl selector to set consistent spaces between all html elements no matter how deeply they are nested, thanks to Heydon Pickering https://alistapart.com/article/axiomatic-css-and-lobotomized-owls/
  * + * {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  .content.container {
    padding: 40px 15px 0;
  }

  .content {
    margin: 0 auto;
    padding: 30px;
    text-align: left;

    h2 {
      padding: 2rem 0;
    }

    li {
      font-size: 1.6rem;
    }

    a {
      font-weight: bold;
    }
  }

  .fade-enter-active {
    transition: all 0.8s ease-in;
  }

  .fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-enter {
    transform: translateY(-5px);
  }

  .fade-leave-to {
    transform: translateY(5px);
  }

  .card {
    border: 2px solid $color-primary;
    border-radius: $card-border-radius;
  }

  .card--grey {
    background: $color-grey-5;
    color: $color-primary;
    h2 {
      padding: 0;
    }
  }

  .card-body {
    padding: 2.3rem;
  }

  &::v-deep .AppCheckbox {
    font-weight: bold;
    input {
      left: 0;
    }
    span:before,
    span:after {
      left: 0;
    }
  }

  .btn {
    width: 100%;
    margin-left: 0;
    &:disabled {
      background: $color-grey-60;
      color: $color-white;
      border-color: $color-grey-60;
      cursor: not-allowed;
    }
  }

  .link-outline {
    &:focus {
      outline-style: auto;
    }
  }
}
