
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationReview {
  padding: $content-padding;

  word-wrap: break-word;
  overflow-wrap: break-word;

  .message {
    display: flex;
    margin: $content-padding 0;
    padding: $content-padding;
    border: 1px solid $color-red;
    border-radius: $field-border-radius;
    background-color: $color-red-lighter;
    font-size: 1.6em;

    .icon {
      margin-right: 0.6em;
      margin-top: 0.2em;
      color: $color-red;
      font-size: 2.5rem;
    }
  }

  .reviewHeading {
    padding: $content-padding;

    h2 {
      margin: 0;
      padding-top: $content-padding * 6;
      padding-bottom: 1em;
      font-weight: $text-bold;
      font-size: $text-h2;
    }

    p {
      font-size: $text-h5;
    }

    .small {
      font-size: $text-small;
    }
  }

  .sections {
    padding: 0 1rem;

    &__toggle {
      @include button-reset;
      font-size: 14px;
    }
  }

  .section {
    padding: $content-padding;

    &__title {
      &--error {
        color: $color-red;
      }
    }
  }

  .fields {
    padding: 0 0 $content-padding;
  }

  .reviewDeclaration {
    padding: 2.5rem 4rem 5rem 4rem;

    &__container {
      padding: 1rem;
      margin-top: 2rem;
    }

    &__declaration-heading {
      margin-top: 3rem;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: bold;
    }

    &__heading {
      font-weight: $text-bold;
      margin-bottom: 2rem;
    }

    &__terms p {
      font-size: 1.2rem;
      line-height: 2rem;
      color: $color-grey-dark-70;
    }

    & ::v-deep {
      .reviewDeclaration__declaration-text {
        font-size: 1.6rem;
        line-height: 2.7rem;
        color: $color-primary;
      }

      .AppCheckbox {
        margin-top: 1rem;
      }

      .AppCheckbox span::before,
      .AppCheckbox span::after,
      input {
        left: 0;
        top: 9px;
      }
    }
  }

  .v-expansion-panel-header {
    background: $color-grey-2;

    &.v-expansion-panel-header--active {
      background: $color-white;
    }
  }

  .alertText {
    margin-left: 2rem;
  }

  .linkButton {
    border: none;
    padding: 0;
    font-weight: 700;
    text-decoration: underline;
  }

  .alertIcon {
    color: $color-orange-2 !important;
    max-width: 24px;
    margin-right: 1rem;
  }
}
