
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.AppSchoolLookup {
  .ApplicationField {
    .fieldText {
      width: 100% !important;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $color-red;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      color: $color-red;
      font-size: 1.4rem;
    }
  }
}
