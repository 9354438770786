
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationMenu {
  $page-top: 75px;

  $menu-item-p: 0.375em;
  $menu-item-h: 3.5em;
  $menu-item-lh: $menu-item-h - ($menu-item-p * 2);

  $menu-icon-cf: $menu-item-lh;

  font-size: 1rem; // Reset to base

  z-index: z('menu');
  position: fixed;
  top: $page-top;
  left: 0;
  padding: 1.5em;
  width: 100%;
  max-width: 33em;
  height: calc(100% - #{$page-top});
  background-color: $color-bg-app;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);

  transform: translateX(-110%);
  transition: transform 0.25s;

  .status-menu {
    position: relative;
    margin: 0;
    padding: 0;
    color: #012040;
    font-size: 1.6rem;
    list-style: none;
  }

  .status-menu::before {
    $w: 0.25em;

    content: '';
    z-index: -1;
    position: absolute;
    top: 50%;
    left: $menu-item-h / 2 - $w / 2;
    width: $w;
    height: calc(100% - (#{$menu-item-p} * 2) - #{$menu-item-lh});
    background-color: #012040;
    transform: translateY(-50%);
  }

  .status-menu__line--disabled::before {
    background-color: #ababab;
  }

  .status-menu__item {
    @include button-reset;
    width: 100%;
    display: flex;
    align-items: center;
    padding: $menu-item-p;
    border-radius: $menu-item-h / 2;
    cursor: pointer;
  }

  .status-menu__icon {
    min-width: $menu-icon-cf;
    min-height: $menu-icon-cf;
    border: 0.1875em solid;
    border-radius: 100%;
    background-color: #fff;
    color: #012040;
    font-weight: 700;
    line-height: $menu-item-lh - $menu-item-p;
    text-align: center;
  }

  .status-menu__title {
    margin-left: 0.75em;
    white-space: nowrap;
  }

  .status-menu__item--current {
    background-color: #fff;
    font-weight: 700;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);
  }

  .status-menu__item--current .status-menu__icon {
    border-color: #012040;
    background-color: #012040;
    color: #fff;
  }

  .status-menu__item--completed {
    font-weight: 700;
  }

  .status-menu__item--completed .status-menu__icon {
    border-color: #289b00;
    background-color: #30b400;
    color: #fff;
  }

  .status-menu__item--failed .status-menu__icon {
    color: #cd0337;
  }

  .status-menu__item--failed .status-menu__title {
    color: #d63c59;
  }
  .status-menu__item.status-menu__item--disabled:disabled {
    color: #ababab;
    cursor: default;
    opacity: 1;
  }
  .status-menu__item--disabled .status-menu__icon {
    color: #ababab;
  }
}

@include desktop {
  .ApplicationMenu {
    $sticky-y: 94px;

    z-index: z('header');
    position: static; // IE11: Fall back to static
    position: sticky;
    top: $sticky-y;
    left: auto;
    padding: 2em 0 2em 2em;
    height: auto;
    background-color: transparent;
    box-shadow: none;

    transform: none;
  }
}

#app.hasMenuOpen {
  overflow: hidden;
  height: 100vh;
}

#app.hasMenuOpen .ApplicationMenu {
  overflow-y: auto;
  transform: translateX(0);
}
