
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

input.uppercase {
  text-transform: uppercase;
}

input::placeholder {
  text-transform: none;
}

.AppTypeAhead {
  position: relative;
}

.AppTypeAhead_input {
  margin: 0;
  padding: 0 1rem;
  height: 4rem;
  border-radius: 0.4rem;
  border: 1px solid $color-grey-15;
  font-size: $text;
  transition: $basic-transition;

  &:focus,
  &:active {
    outline: none;
    border-color: $color-primary;
  }

  @include placeholder {
    color: $color-placeholders;
  }
}

.AppTypeAhead_options {
  font-size: $text;
  max-height: 260px;

  .selected {
    // keydown (arrows keys action) style
    background-color: $color-third;
    border-color: $color-third;
    color: $color-black;
  }
}

.AppTypeAhead_options {
  z-index: z('dropdown');
  overflow-y: auto;
  width: 100%;
  position: absolute; // Necessary for all the calculations to work in scrollSelectionIntoView()
  padding: 0.1em 0;
  border: 1px solid #ddd;
  border-radius: 0.2rem;
  background-color: $color-white;
  color: $color-black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.AppTypeAhead_option {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0.5em 1em;
  cursor: pointer;
  &:hover {
    display: flex !important; // that allow to make dropdown clickable on IE10=>UP
    background-color: $color-primary;
    border-color: $color-primary;
    outline: none;
    color: $color-white;
  }
}

.spinner {
  font-weight: bold;
  padding: 1em;
  display: none;

  &.isLoading {
    display: inline-block;
  }

  .icon {
    animation: spin 3s infinite linear;
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@include mobile {
  .AppTypeAhead_option {
    flex-direction: column;

    &_alternate {
      font-size: 1.2rem;
    }
  }
}
