
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.ApplicationReviewCollection {
  .ApplicationFieldCollectionHeading {
    padding: 0 0 2rem;

    h3 {
      margin-bottom: 0;
    }
  }

  h3:first-child {
    margin-top: $content-padding * 1.25;
  }

  .collectionItemFields {
    margin: 0;
    padding: 0;
  }
}
